import React from "react"
import Banner from "../components/banner/banner"
import Nav from "../components/navigation/navigation"
import Footer from "../components/footer/footer"
import SEO from "../components/seo"
import { useState } from "react"

const ThanksPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  return (
    <div>
      <SEO title="Form Sent" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title="Form Sent"
        bgClass="not-found"
        cta="Return"
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default ThanksPage

export const thanksQuery = graphql`
  query ThanksQuery {
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
  }
`
